import {IMG_FILE_TYPE, PASS_GT_MAX_LENGTH, PASS_NOT_RULE} from "./message";

//super user
export const commonSuperValidate = function (name,value) {
    var res = true;
    if(name === 'firstName') {
        res = firstNameValidate('First Name',value)
        return res;
    }else if(name === 'lastName') {
        res = firstNameValidate('Last Name',value)
        return res;
    }else if(name === 'userName') {
        res = loginNameValidate('Login Name',value)
        return res;
    }else if(name === 'email') {
        res = emailValidate('Email',value)
        return res;
    }else if(name === 'cellPhone' ) {
        res = cellPhoneValidate('Cell Phone',value)
        return res;
    }else if(name === 'officePhone') {
        res = cellPhoneValidate('Office Phone',value)
        return res;
    }else {
        return res;
    }
};

//super user reset pass
export const commonPassWordValidate = function (name,value) {
    let res = true;
    if(name === 'password') {
        res = passwordValidate('New Password',value)
        return res;
    }else if(name === 'ConfirmPassword') {
        res = passwordValidate('Confirm New Password',value)
        return res;
    }else if(name === 'npassword') {
        res = passwordValidate('New Password',value)
        return res;
    }else if(name === 'cnpassword') {
        res = passwordValidate('Confirm Password',value)
        return res;
    }else {
        return res;
    }
};

export const commonValidate = function (name,value) {
    var res = true;
    if(name === 'upload logo' || name === 'upload photo'){
        res = logoValidate(name,value)
        return res;
    }else if(name === 'loginName') {
        res = loginNameValidate('Login Name',value)
        return res;
    }else if(name === 'email') {
        res = emailValidate('Email',value)
        return res;
    }else {
        return res;
    }
};

export const loginNameValidate = function (name,value) {
    return true;
};

//at least 8 characters long with at least one Capital letter, one special letter, and one number
export const passwordValidate = function (name,value) {
    let length = validateLength(value);
    if(!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?\\/])[A-Za-z\d~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?\\/]{8,128}$/.test(value))) {
        if(length > 128 ) {
            return PASS_GT_MAX_LENGTH;
        }else {
            return PASS_NOT_RULE;
        }
    }else {
        return true;
    }
};

export const firstNameValidate = function (name,value) {
    return true;
};

export const emailValidate = function (name,value) {
    return true;
};

export const cellPhoneValidate = function (name,value) {
    return true;
};

export const logoValidate = function (name,value) {
    if(!(/\.(jpg|png|jpeg)$/.test(value.toLowerCase()))) {
        return IMG_FILE_TYPE;
    }else {
        return true;
    }
};

export const validateLength = function (str) {
    return str.length;
};





