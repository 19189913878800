import {getpackage} from "../api";

export const getPackageJson=async ()=>{
    let result = await getpackage();
    let version_number=result.version_number+result.release_number;
    if(localStorage.getItem('user_version_number') != version_number){
        if(localStorage.getItem('user_version_number') === null){
            localStorage.setItem('user_version_number',version_number);
        }else {
            localStorage.setItem('user_version_number',version_number);
            window.location.reload();
        }
    }
}

export const extractLength = function (length,value) {
    if(length == -1 || length == '' || length == undefined){
        length=255
    }
    if(value.length > length){
        return value.substring(0,length)
    }else {
        return value
    }
}