import _ from 'lodash'
import PubSub from "pubsub-js";
import {TIMEOUT} from "./index";

let timeOut = TIMEOUT;
let lastTime = new Date().getTime();
let currentTime = new Date().getTime();
let ICE_CONTAINER = document.getElementById('root');
let timeType = false;

const OvertimeLogin=(status)=> {
    if(status === 'int'){
        lastTime=new Date().getTime();
    }else {
        let testTime=()=> {
            let changetime = _.debounce(function(e){
                lastTime=new Date().getTime();
            },100, { 'maxWait': 2000 })

            ICE_CONTAINER.addEventListener('mousemove', function() {
                changetime();
            })
            timeType = window.location.href.indexOf('login') < 0;
            currentTime = new Date().getTime();
            let user_lasttime = localStorage.getItem('user_lasttime');
            // console.log('user-station',currentTime,lastTime, (currentTime - lastTime)/1000,(currentTime - user_lasttime)/1000);
            if (currentTime - lastTime > timeOut) {
                if(currentTime - user_lasttime > timeOut){
                    if (timeType) {
                        window.clearInterval(global.overtime);
                        PubSub.publish('logout', true)
                    }
                }
            }
        }
        return testTime
    }
}

export default OvertimeLogin