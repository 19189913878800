import React,{useState} from 'react';
import {Container, Modal} from "react-bootstrap";
import "./alert.css"
import DraggableDivLast from "../draggable/draggableDivLast";


export default function AlertTips(props) {
    const {status,body} = props;
    const [show, setShow] = useState(status);
    if(status !== show){
        setShow(status)
    }

    function handleClose(status) {
        props.AlertTip(status,body);
    }
    const alert_body=(
        <>
            <div className="modal-header background-green1">
                <div className="h6 modal-title h4 text-white small_font">Message:</div>
                <button tabIndex={120} type="button" id="btn_close_tip" className="btn-close" aria-label="Close" onClick={handleClose.bind(this,false)}></button>
            </div>
            <Modal.Body className="show-grid small_font text-center">
                <Container>
                    {body}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <ul className="row form-group m-0 pr-0 list-inline">
                    <li className="org_li_wid">
                        <button className="button resend-btn px-4 py-2 m-0" onClick={handleClose.bind(this,false)}>
                            OK
                        </button>
                    </li>
                </ul>
            </Modal.Footer>
        </>
    )

    return (
        <>
        {show === true ?
            <DraggableDivLast body={alert_body}></DraggableDivLast>
            :''}
        </>
    );
}



