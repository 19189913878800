import React, {Component} from 'react';
import {Nav,NavDropdown,Navbar,Container} from 'react-bootstrap'
import PubSub from 'pubsub-js'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import logo from "../../assets/images/WizDepotLogoV2.png";
import "./top.css"
import {getUser,getUserPhoto} from "../../api";
import menu from "../../config/menu";
import user_default from '../../assets/images/user-default.png'
import person from '../../assets/images/person.svg'
import out from '../../assets/images/out.svg'

class Top extends Component {
    constructor(props) {
        super(props);
        let arr=window.location.hash.split('/');
        let path;
        const path_route=arr[2];
        if(path_route==="admin"){
            path="timesheet"
        }
        else {
            path=path_route;
        }
        this.state = {
            activeKey:path,
            menu:menu,
            photo:user_default,
            user:{},
            uid:'',
        };
    }

    componentDidMount() {
        const {uidUsr} = this.props.userInfo;
        this.setState({
            uid:uidUsr,
        });
        this.getUser(uidUsr);
        this.getUserPhoto(uidUsr);
        this.props.history.listen(location => {
            let arr=window.location.hash.split('/');
            let path;
            const path_route=arr[2];
            if(path_route==="admin"){
                path="timesheet"
            }
            else {
                path=path_route;
            }
            this.setState({
                activeKey:path
            })
        })
        PubSub.subscribe('uploadPhoto',(msg,data)=> {
            if(data===true){
                this.getUserPhoto(uidUsr);
            }else {
                this.setState({
                    photo:user_default,
                });
            }
        })
    }
    //get user
    getUser = async(uid)=>{
        let resultUser = await getUser(uid);
        this.setState({
            user:resultUser,
        });
    };

    //get photo
    getUserPhoto = async(uid)=>{
        let photo = await getUserPhoto(uid);
        let blob = new Blob([photo],{type: "image/png,image/jpg,image/jpeg,image/bmp"});
        let url = window.URL.createObjectURL(blob);
        var Img = new Image();
        Img.src = url;
        Img.onerror = function(err) {
            url=user_default;
        }
        this.setState({
            photo:url,
        });
    };

    goProFile=()=>{
        this.props.history.push("/admin/user_profile")
    }

    logout() {
        this.props.logout();
    }

    onNavClick=(event)=>{
        this.setState({
            activeKey:event
        })
    }

    componentWillUnmount() {
        PubSub.unsubscribe('uploadPhoto');
        this.setState = ()=>false;
    }

    render() {
        return (
            <div className="admin-header header_height">
                <Navbar collapseOnSelect expand="lg" sticky="top" className="p-0">
                    <Container>
                        <Navbar.Brand>
                            <div className="top_left_span">
                                <div className="float-start width_px_140">
                                      <span className="float-start width-100 top_title_big_span">
                                        <b>Wiz</b>Depot
                                    </span>
                                    <span className="xs_font text-white float-start width-100 top_title_small_span">User Station</span>
                                </div>
                                <img className="top_left_img" src={logo} alt="logo" />
                            </div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="bg-white mr-1" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="ms-0 header_height">
                        <Nav variant="tabs" activeKey={this.state.activeKey} onSelect={(event)=>this.onNavClick(event)} className="me-auto">
                            {
                                this.state.menu.map((item,index)=>{
                                    return (
                                        <Nav.Item key={item.key} className="header_height">
                                            <Nav.Link id={item.key} eventKey={item.key} tabIndex={index+1} href={item.path}>{item.title}</Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                            }

                        </Nav>
                        <Nav
                            onBlur={(e)=>{
                                document.body.click()
                            }}
                        >
                            <NavDropdown className="NavDropdown_top" title={<img className="NavDropdown_title" src={this.state.photo} alt="logo"/>}
                                         tabIndex={5} id="collasible-nav-dropdown">
                                <NavDropdown.Item onClick={this.goProFile.bind(this)} tabIndex={6}>
                                    <img className="NavDropdown_Item_img" alt="" src={person} />
                                    <span className="font_color">Profile</span>
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={this.logout.bind(this)} tabIndex={7}>
                                    <img className="NavDropdown_Item_img" alt="" src={out} />
                                    <span className="font_color">Logout</span>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}

export default connect(
    state=>({userInfo:state.userInfo}),
    {
    }
)(withRouter(Top))