//message define
//US-2,US-8,US-12,US-51
export const ALL_REQUIRED = 'Please fill in required fields.';
export const ALL_REQUIRED_INFORMATION = 'Please fill in all required information.';
//US-10
export const RESET_INSTRUCTION_SENT = 'Reset Instruction has been sent.';
//US-15
export const PASS_CPASS_NOT_MATCH = 'The new password and confirmed password do not match.';
//US-13
export const PASS_NOT_RULE = 'Password must be at least 8 characters long with at least one lower case letter, one upper case letter, one digit and one special letter.';
//US-16
export const PASS_GT_MAX_LENGTH = 'Password length exceeds 128 characters.';
//US-30
export const IMG_FILE_MAX_SIZE = 'The file size cannot exceed 1MB.';
//US-31
export const IMG_FILE_TYPE = 'Only JPEG and PNG document can be uploaded.';


//back message control
//US-3
export const LOGIN_FAILED = 'Login failed. Your login name or password is incorrect.';
//US-4,US-5
export const LOGIN_ACCOUNT_FAILED = 'Your account is terminated.';
//US-9
export const FORGET_SUBMIT_FAILED = 'Submitted record does not exist.';
export const RESET_EMAIL_ERROR = 'Fail to send out reset email, please come back latter.';
//US-11
export const ACCOUNT_NOT_ACTIVE = ' User account is not active.';
//US-14
export const CURRENT_PASS_ERROR = 'The current password is incorrect.';
//US-17
export const FIRST_NAME_REQUIRED = 'First name is required.';
//US-19
export const LAST_NAME_REQUIRED = 'Last name is required.';
//US-24
export const EMAIL_ADDRESS_REQUIRED = 'Email address is required.';
//US-26
export const PHONE_REQUIRED = 'Phone number is required.';
//US-21
export const LOGIN_NAME_REQUIRED = 'Login name is required.';
//US-23A
export const LOGIN_NAME_RULE_ERR = 'Login name cannot contain space.';
//us-23
export const LOGIN_NAME_ALREADY = 'The requested login name is not available.';
export const EMAIL_ALREADY = 'The email has already been used.';

//US-5A
export const COMPANY_ACCOUNT_SUS_TERMINATED = 'Your account is suspended/ terminated.';

export const EDIT_ALREADY_BODY= 'Record has been changed, please refresh the data and try again.';

