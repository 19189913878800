import React, {Component} from 'react';
import {resetPassEmailRequest, resetPassEmailRequestByEmail} from "../../api";
import {Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import logo from "../../assets/images/WizDepotLogo.jpg";
import userSvg from "../../assets/images/user.png";
import "./forget.css"
import {commonValidate} from "../../config/commonValidate";
import {ALL_REQUIRED} from "../../config/message";
import {MessageControl} from "../../config/alert_message";
import {extractLength} from "../../config/commom";

class Forget extends Component {
    state = {
        username:'',
        email:'',
        status:'username',
        danger:"",
        success:'',
        validated:false,
    };

    handleSubmit = async(event)=>{
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.setState({
                danger:ALL_REQUIRED,
                success:'',
                validated:true,
            });
        }else {
            if(this.state.status === 'username'){
                const { username} = event.target.elements;
                let validate = commonValidate('loginName',username.value);
                if(validate != true){
                    this.setState({
                        danger:validate,
                        success:'',
                    });
                    return ;
                }
                let body={userName:username.value};
                let result = await resetPassEmailRequest(body);
                const {code,msg} = result;
                let message=MessageControl('forget',msg);
                if(code === 1){
                    this.setState({
                        success:message,
                        danger:'',
                    });
                }else {
                    this.setState({
                        danger: message ,
                        success:'',
                    });
                }
            }else {
                const { email} = event.target.elements;
                let validate = commonValidate('email',email.value);
                if(validate !== true){
                    this.setState({
                        danger:validate,
                        success:'',
                    });
                    return ;
                }

                let body={email:email.value};
                let result = await resetPassEmailRequestByEmail(body);
                const {code,msg} = result;
                let message=MessageControl('forget',msg);
                if(code === 1){
                    this.setState({
                        success:message,
                        danger:'',
                    });
                }else {
                    this.setState({
                        danger: message ,
                        success:'',
                    });
                }
            }

        }
    };

    login=()=>{
        this.props.history.push("/login")
    }

    onKeyDown = (event) => {
        if (event.key === "Tab") {
            event.preventDefault();
            document.getElementById(this.state.status).focus();
        }
    };

    render() {
        return (
            <div className="background_login not_select">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs={12} className="justify-content-magin">
                            <Col lg={4} md={6} sm={9} className="login-form text-center">
                                <div className="logo_box_header">
                                    <div className="logo_box_header_title">
                                        <span>
                                            <b>Wiz</b>
                                            Depot
                                        </span>
                                        <img className="logo_box_header_logo" src={logo} alt="logo" />
                                    </div>
                                </div>

                                <div className="logo_box_header_two">
                                    <span className="logo_box_header_two_title">
                                        Reset Password
                                    </span>
                                </div>
                                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} >
                                    {this.state.status === 'username' ?
                                        <>
                                            <div className="forget_logo_box_header_two">
                                                <span className="logo_box_header_two_title_three">
                                                    Enter Your User Name below to have instructions to reset your password
                                                </span>
                                            </div>
                                            {this.state.danger ? <span className="text-danger small_font">{this.state.danger}</span> : <span></span>}
                                            {this.state.success ? <span className="text-success small_font">{this.state.success}</span> : <span></span>}

                                            <Form.Group className="mb-3" controlId="username">
                                                <InputGroup>
                                                    <InputGroup.Text id="btnGroupAddon2">
                                                        <img className="login_imput_logo" alt="" src={userSvg}></img>
                                                    </InputGroup.Text>
                                                    <Form.Control tabIndex={1} autoComplete="off" type="text" placeholder="Login Name*" value={this.state.username}
                                                                  maxLength={128}
                                                                  onChange={(e)=>{
                                                                      // e.target.value=e.target.value.toString().replace(/(^\s*)/g, "");
                                                                      // e.target.value=extractLength(e.target.maxLength,e.target.value);
                                                                      let fdStart = e.target.value.indexOf(" ");
                                                                      if (fdStart === 0){
                                                                          e.target.value=e.target.value.toString().replace(/(^\s*)/g, "");
                                                                      }
                                                                      this.setState({username:e.target.value})
                                                                  }}
                                                                  required />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group className="mb-2 float-end">
                                                <span tabIndex={1} className="forget_label_user_email blue-color"
                                                      onClick={(e)=>{
                                                          this.setState({
                                                              status:'email',
                                                              danger:"",
                                                              success:'',
                                                              validated:false,
                                                          })
                                                      }}><u>Forgot login name?</u></span>
                                            </Form.Group>
                                        </>
                                    :
                                        <>
                                            <div className="forget_logo_box_header_two">
                                                <span className="logo_box_header_two_title_three">
                                                    Please enter your email below, we will email you instruction for password reset.
                                                </span>
                                            </div>
                                            {this.state.danger ? <span className="text-danger small_font">{this.state.danger}</span> : <span></span>}
                                            {this.state.success ? <span className="text-success small_font">{this.state.success}</span> : <span></span>}
                                            <Form.Group className="mb-3" controlId="email">
                                                <InputGroup>
                                                    <InputGroup.Text id="btnGroupAddon2">
                                                        <img className="login_input_logo" alt="" src={userSvg}></img>
                                                    </InputGroup.Text>
                                                    <Form.Control tabIndex={1} autoComplete="off" type="text" placeholder="Email*" value={this.state.email}
                                                                  maxLength={255}
                                                                  onChange={(e)=>{
                                                                      // e.target.value=e.target.value.toString().replace(/(^\s*)/g, "");
                                                                      // e.target.value=extractLength(e.target.maxLength,e.target.value);
                                                                      let fdStart = e.target.value.indexOf(" ");
                                                                      if (fdStart === 0){
                                                                          e.target.value=e.target.value.toString().replace(/(^\s*)/g, "");
                                                                      }
                                                                      this.setState({email:e.target.value})
                                                                  }}
                                                                  required />
                                                </InputGroup>
                                            </Form.Group>
                                            {/*<Form.Group className="mb-2 float-end">*/}
                                            {/*    <span tabIndex={1} className="forget_label_user_email blue-color"*/}
                                            {/*          onClick={(e)=>{*/}
                                            {/*              this.setState({*/}
                                            {/*                  status:'username',*/}
                                            {/*                  danger:"",*/}
                                            {/*                  success:'',*/}
                                            {/*                  validated:false,*/}
                                            {/*              })*/}
                                            {/*          }}><u>Forgot by login name</u></span>*/}
                                            {/*</Form.Group>*/}
                                        </>
                                    }

                                    <input type="submit" tabIndex={2} className="forget_button button w-100 py-2 float-start" value="Send Instructions" />
                                    <input type="button" tabIndex={3} onKeyDown={this.onKeyDown.bind(this)}
                                           onClick={this.login.bind(this)} className="button w-100 py-2 mt-1" value="Cancel" />
                                </Form>
                            </Col>
                            <Col xs={12} className="text-center xs_font light_color px-2 mt-2 mb-0">
                                <span>(c) 2016 WizDepot. All Rights Reserved</span>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Forget;