import React, {Component} from 'react';
import {connect} from "react-redux";
import IframeBox from "../iframe_box/iframe_box";
import {getPackageJson} from "../../config/commom";

class Timesheet extends Component {
    componentDidMount() {
        getPackageJson();
    }

    render() {
        const {uidUsr,tokenUsr} = this.props.userInfo;
        return (
            <div>
                <IframeBox uidUsr={uidUsr} tokenUsr={tokenUsr}/>
            </div>
        );
    }
}
export default connect(
    state=>({userInfo:state.userInfo}),
    {}
)(Timesheet)