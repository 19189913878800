import React, {Component} from 'react';
import Draggable from "react-draggable";

class DraggableDiv extends Component {
    state={
        bounds: { left: -800, top: -30, bottom: 800, right: 800 }
    }

    render() {
        return (
            <div>
                <div className="fade modal-backdrop show"></div>
                <div role="dialog" aria-modal="true" className="fade modal show alert_dia_div" tabIndex="-1">
                    <div className="modal-dialog modal-md modal-dialog-scrollable">
                        <Draggable
                            bounds={this.state.bounds}
                            handle='.drag-handler'
                        >
                            <div className="modal-content">
                                {this.props.body}
                            </div>
                        </Draggable>
                    </div>
                </div>
            </div>
        );
    }
}

export default DraggableDiv;