import React, {Component} from 'react';
import {Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import qs from 'qs'
import logo from "../../assets/images/WizDepotLogo.jpg";
import passSvg from "../../assets/images/pass.png";
import {commonPassWordValidate} from "../../config/commonValidate";
import {resetPassByEmail} from "../../api";
import {ALL_REQUIRED, PASS_CPASS_NOT_MATCH} from "../../config/message";
import {MessageControl} from "../../config/alert_message";
import {extractLength} from "../../config/commom";

class EmailUrl extends Component {
    state = {
        editPass:{},
        errors:{},
        token:'',
        danger:"",
    };

    componentDidMount() {
        const {search} = this.props.location;
        const {ref} = qs.parse(search.slice(1));
        this.setState({
            token:ref
        })
    }

    handleChangePass = (e) => {
        // e.target.value=e.target.value.toString().replace(/(^\s*)/g, "");
        // e.target.value=extractLength(e.target.maxLength,e.target.value);
        let fdStart = e.target.value.indexOf(" ");
        if (fdStart === 0){
            e.target.value=e.target.value.toString().replace(/(^\s*)/g, "");
        }
        let editPass = this.state.editPass;
        let errors = this.state.errors;
        editPass[e.target.name] = e.target.value;
        let validate = commonPassWordValidate(e.target.name,e.target.value);
        if(validate !== true){
            errors[e.target.name] = 'is-invalid';
        }else {
            errors[e.target.name] = 'is-validWeb';
        }
        this.setState({
            editPass ,
            errors,
        });
    }

    handleSubmit = async(event)=>{
        event.preventDefault();
        this.setState({
            danger:'',
        });
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.setState({
                danger:ALL_REQUIRED,
                validated:true,
            });
        }else {
            let editPass = this.state.editPass;
            const { password, ConfirmPassword } = this.state.editPass;
            if (password === ConfirmPassword) {
                for (let i in editPass) {
                    if(editPass[i] != ''){
                        let validate = commonPassWordValidate(i,editPass[i]);
                        if(validate != true){
                            this.setState({
                                danger:validate,
                            });
                            return ;
                        }
                    }
                }
                let body = {userName:this.state.token,newPassword:password}
                let result = await resetPassByEmail(body);
                const {code,msg} = result;
                if(code === 1){
                    this.props.history.replace('/login')
                }else {
                    let message=MessageControl('reset_email',msg);
                    this.setState({ danger: message });
                }
            }else {
                this.setState({
                    danger: PASS_CPASS_NOT_MATCH
                });
            }
        }
    };

    render() {
        const {editPass,errors}=this.state;
        return (
            <div className="background_login not_select">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs={12} className="justify-content-magin">
                            <Col lg={4} md={6} sm={9} className="login-form text-center">
                                <div className="logo_box_header">
                                    <div className="logo_box_header_title">
                                        <span>
                                            <b>Wiz</b>
                                            Depot
                                        </span>
                                        <img className="logo_box_header_logo" src={logo} alt="logo" />
                                    </div>
                                </div>

                                <div className="logo_box_header_two">
                                    <span className="logo_box_header_two_title">
                                        Reset Password
                                    </span>
                                </div>

                                <div className="forget_logo_box_header_two">
                                    <span className="logo_box_header_two_title_three">
                                        Enter Your New Password And Confirm Password to reset your password
                                    </span>
                                </div>
                                {
                                    this.state.danger ? <span className="text-danger small_font">{this.state.danger}</span> : <span></span>
                                }
                                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} >

                                    <Form.Group className="mb-3" controlId="password">
                                        <InputGroup>
                                            <InputGroup.Text id="btnGroupAddon2">
                                                <img className="login_imput_logo" alt="" src={passSvg}></img>
                                            </InputGroup.Text>
                                            <Form.Control tabIndex={1} autoComplete="off" type="password" placeholder="Enter your new password" name="password"
                                                          className={(errors["password"] ? errors["password"] : '')} onChange={this.handleChangePass}
                                                           value={editPass.password || ''} required />
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="ConfirmPassword">
                                        <InputGroup>
                                            <InputGroup.Text id="btnGroupAddon3">
                                                <img className="login_imput_logo" alt="" src={passSvg}></img>
                                            </InputGroup.Text>
                                            <Form.Control tabIndex={2} autoComplete="off" type="password" placeholder="Enter your confirm password" name="ConfirmPassword"
                                                          className={(errors["ConfirmPassword"] ? errors["ConfirmPassword"] : '')} onChange={this.handleChangePass}
                                                          value={editPass.ConfirmPassword || ''} required />
                                        </InputGroup>
                                    </Form.Group>
                                    <input type="submit" tabIndex={3} className="forget_button button w-100 py-2" value="Confirm" />

                                    {/*<input type="button" tabIndex={4} onKeyDown={this.onKeyDown.bind(this,'username')} onClick={this.login.bind(this)} className="button w-100 py-2 mt-2" value="Cancel" />*/}
                                </Form>
                            </Col>
                            <Col xs={12} className="text-center xs_font light_color px-2 mt-2 mb-0">
                                <span>(c) 2016 WizDepot. All Rights Reserved</span>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default EmailUrl;