import myAxios from './myAxios'
import {BASE_URL} from "../config";

//login
export const reqLogin = (body) => myAxios.post(`${BASE_URL}/UserStation/rest/employees/login`,body);

//get user
export const getUser = (uid) => myAxios.get(`${BASE_URL}/UserStation/rest/employees/`+uid);

//get photo
export const getUserPhoto = (uid) =>
    myAxios.request({
        url:`${BASE_URL}/UserStation/rest/images?uid=`+uid+'&itemtype=EmployeePhoto',
        responseType:'blob',
        method:'get'
    })

//update user
export const updateUser = (uid,body) => myAxios.put(`${BASE_URL}/UserStation/rest/employees/`+uid,body);

//upload user Photo
export const uploadUserPhoto = (formData) => myAxios.post(`${BASE_URL}/UserStation/rest/images`,formData);

//change User Password
export const changeUserPassword = (body) => myAxios.post(`${BASE_URL}/UserStation/rest/employees/password-reset`,body);

//delete User Photo
export const deleteUserPhoto = (uid) => myAxios.delete(`${BASE_URL}/UserStation/rest/employees/images?uid=`+uid);

//reset email
export const resetPassEmailRequest = (body) => myAxios.post(`${BASE_URL}/ManagerStation/rest/compos-employees/reset-password-request`,body);

//reset email by email
export const resetPassEmailRequestByEmail = (body) => myAxios.post(`${BASE_URL}/ManagerStation/rest/compos-employees/reset-password-byemail`,body);

//reset password (email)
export const resetPassByEmail = (body) => myAxios.post(`${BASE_URL}/ManagerStation/rest/compos-employees/forgot-password-reset`,body);

//token Api Reset
export const tokenApiReset = () => myAxios.post(`${BASE_URL}/UserStation/rest/employees/touch`);

export const logoutApi = (token) =>
    myAxios.request({
        url:`${BASE_URL}/UserStation/rest/employees/logout`,
        method:'post',
        headers:{Authorization:token}
    })

// asset-manifest.json
export const getpackage = () => myAxios.get(`${BASE_URL}/user-station/version/version.json`);
// export const getpackage = () => myAxios.get(`http://a.com/user-station/version/version.json`);