//Back end interface control
import {
    LOGIN_FAILED,
    LOGIN_ACCOUNT_FAILED,
    FORGET_SUBMIT_FAILED,
    RESET_INSTRUCTION_SENT,
    RESET_EMAIL_ERROR,
    ACCOUNT_NOT_ACTIVE,
    CURRENT_PASS_ERROR,
    FIRST_NAME_REQUIRED,
    LAST_NAME_REQUIRED,
    LOGIN_NAME_REQUIRED,
    LOGIN_NAME_RULE_ERR,
    LOGIN_NAME_ALREADY,
    EMAIL_ADDRESS_REQUIRED,
    EMAIL_ALREADY,
    PHONE_REQUIRED,
    COMPANY_ACCOUNT_SUS_TERMINATED
} from "./message";

export const MessageControl = function (name, value) {
    if(name==='login'){
        switch(value) {
            case "Login failed: your login name or password is incorrect":
                return LOGIN_FAILED;
            case "Login failed. Your login name or password is incorrect.":
                return LOGIN_FAILED;
            case "User account is terminated":
                return LOGIN_ACCOUNT_FAILED;
            case "Your account is terminated.":
                return LOGIN_ACCOUNT_FAILED;
            case "User account is deactivated":
                return LOGIN_ACCOUNT_FAILED;
            case "Inactive organization":
                return COMPANY_ACCOUNT_SUS_TERMINATED;
            default:
                return value;
        }
    }

    if(name==='forget'){
        switch(value) {
            case "Record does not exist":
                return FORGET_SUBMIT_FAILED;
            case "Submitted record does not exist.":
                return FORGET_SUBMIT_FAILED;
            case "System error: sending email failed":
                return RESET_EMAIL_ERROR;
            case "Fail to send out reset email, please come back latter.":
                return RESET_EMAIL_ERROR;
            case "Send Instructions Success":
                return RESET_INSTRUCTION_SENT;
            case "Email sent successfully":
                return RESET_INSTRUCTION_SENT;
            case "Reset Instruction has been sent.":
                return RESET_INSTRUCTION_SENT;
            case "User account is not in active status":
                return ACCOUNT_NOT_ACTIVE;
            case " User account is not active.":
                return ACCOUNT_NOT_ACTIVE;
            default:
                return value;
        }
    }

    if(name==='user_profile'){
        switch(value) {
            case "First name is required":
                return FIRST_NAME_REQUIRED;
            case "First name is required.":
                return FIRST_NAME_REQUIRED;
            case "Last name is required":
                return LAST_NAME_REQUIRED;
            case "Last name is required.":
                return LAST_NAME_REQUIRED;
            case "Login name is required":
                return LOGIN_NAME_REQUIRED;
            case "Login name is required.":
                return LOGIN_NAME_REQUIRED;
            case "Email address is required":
                return EMAIL_ADDRESS_REQUIRED;
            case "Email address is required.":
                return EMAIL_ADDRESS_REQUIRED;
            case "Phone number is required":
                return PHONE_REQUIRED;
            case "Phone number is required.":
                return PHONE_REQUIRED;
            case "Login name is invalid (may not contain space)":
                return LOGIN_NAME_RULE_ERR;
            case "Login name cannot have space.":
                return LOGIN_NAME_RULE_ERR;
            case "Login name cannot contain space.":
                return LOGIN_NAME_RULE_ERR;
            case "Login name is already taken":
                return LOGIN_NAME_ALREADY;
            case "The requested login name is not available.":
                return LOGIN_NAME_ALREADY;
            case "Email is already taken":
                return EMAIL_ALREADY;
            case "The email has already been used.":
                return EMAIL_ALREADY;



            default:
                return value;
        }
    }

    if(name==='upload'){
        return value;
    }

    if(name==='edit_password'){
        switch(value) {
            case "Authentication failed":
                return CURRENT_PASS_ERROR;
            case "The current password is incorrect.":
                return CURRENT_PASS_ERROR;
            default:
                return value;
        }
    }

    if(name==='reset_email'){
        return value;
    }

    if(name==='delete_photo'){
        return value;
    }
};
















