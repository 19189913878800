import React, {Component} from 'react';
import {Redirect,Route,Switch} from 'react-router-dom'
import {createDeleteUserInfoAction} from "../../redux/actions/login_action";
import {connect} from "react-redux";
import {Container, Row, Col} from 'react-bootstrap'
import './admin.css'
import Top from "../../pages/top/top"
import UserProfile from "../../pages/user_profile/user_profile";
import Timesheet from "../../pages/timesheet/timesheet";
import {getPackageJson} from "../../config/commom";
import OvertimeLogin from "../../config/overtime";
import PubSub from "pubsub-js";
import {TOKENRESETTIME, TRIGGERTIMEOUT} from "../../config";
import {logoutApi, tokenApiReset} from "../../api";


class Admin extends Component {
    state = {
        uid:'',
        token:'',
    };

    componentDidMount() {
        const {uidUsr,tokenUsr} = this.props.userInfo;

        this.setState({
            uid:uidUsr,
            token:tokenUsr
        });
        getPackageJson();
        OvertimeLogin('int');
        setTimeout(function(){
            global.overtime = window.setInterval(OvertimeLogin(), TRIGGERTIMEOUT);
        },200);
        let that = this;
        global.tokenTime = setInterval(function(){
            that.tokenTimeReset()
        },TOKENRESETTIME)

        PubSub.subscribe('logout',(msg,data)=> {
            if(data===true){
                this.logout();
            }
        })
    }

    tokenTimeReset=()=>{
        tokenApiReset();
    }

    logout(){
        window.clearInterval(global.overtime);
        window.clearInterval(global.tokenTime);
        this.props.deleteUserInfo()
    }

    componentWillUnmount() {
        logoutApi(this.state.token);
        PubSub.unsubscribe('logout');
        this.setState = ()=>false;
    }

    render() {
        const {isLoginUsr} = this.props.userInfo;
        if(!isLoginUsr){
            return <Redirect to="/login"/>
        }else{
            return (
                <div className="background not_select" id="admin_background">
                    <Container className="admin_container">
                        <Row>
                            <Col className="admin_col">
                                <Top logout={this.logout.bind(this)}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="admin_col">
                                <Switch>
                                    <Route path="/admin/user_profile" component={UserProfile}/>
                                    <Route path="/admin/timesheet" component={Timesheet}/>
                                    <Redirect to="/admin/timesheet"/>
                                </Switch>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }
    }
}

export default connect(
    state=>({userInfo:state.userInfo}),
    {
        deleteUserInfo:createDeleteUserInfoAction
    }
)(Admin)
