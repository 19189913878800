import React, {Component} from 'react';
import {connect} from "react-redux";
import {Form,InputGroup,Container,Row,Col} from 'react-bootstrap'
import {createSaveUserInfoAction} from "../../redux/actions/login_action";
import {reqLogin} from "../../api";
import "./login.css"
import logo from '../../assets/images/WizDepotLogo.jpg';
import passSvg from '../../assets/images/pass.png'
import userSvg from '../../assets/images/user.png'
import {ALL_REQUIRED} from "../../config/message";
import {MessageControl} from "../../config/alert_message";

class Login extends Component {

    state = {
        username:localStorage.getItem('keyNameUsr'),
        password:localStorage.getItem('keyPassUsr'),
        checked:localStorage.getItem('isCheckedUsr'),
        danger:"",
    };

    componentDidMount() {
        const {isLoginUsr} =this.props;
        if(!isLoginUsr){
            document.getElementById("username").focus();
        }
    }

    //login
    handleSubmit = async(event)=>{
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.setState({
                danger:ALL_REQUIRED,
            });
        }
        this.setState({
            validated:true,
        });
        const { username, password,rememberCheckbox} = event.target.elements;
        if(username.value && password.value){
            let body = {userName:username.value,passWord:password.value};
            let result = await reqLogin(body)
            const {code,msg} = result;
            if(code===1){
                this.props.saveUserInfo(result);
                if(rememberCheckbox.checked){
                    localStorage.setItem('isCheckedUsr',true);
                    localStorage.setItem('keyNameUsr',username.value);
                    localStorage.setItem('keyPassUsr',password.value);
                }else{
                    localStorage.removeItem('isCheckedUsr');
                    localStorage.removeItem('keyNameUsr');
                    localStorage.removeItem('keyPassUsr');
                }
                this.props.history.replace('/admin/timesheet')
            } else {
                let message=MessageControl('login',msg);
                this.setState({
                    danger:message
                });
            }
        }
    };

    onKeyDown = (index,event) => {
        if (event.key === "Tab") {
            event.preventDefault();
            document.getElementById(index).focus();
        }

        if(event.keyCode === 13 && index==='login_button'){
            this.props.history.push("/forget")
        }
    };

    forget=()=>{
        this.props.history.push("/forget")
    };

    render() {
        return (
            <div className="background_login not_select">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs={12} className="justify-content-magin">
                            <Col lg={4} md={6} sm={9} className="login-form text-center">
                                <div className="logo_box_header">
                                    <div className="logo_box_header_title">
                                        <span>
                                            <b>Wiz</b>
                                            Depot
                                        </span>
                                        <img className="logo_box_header_logo" src={logo} alt="logo" />
                                    </div>
                                </div>

                                <div className="logo_box_header_two">
                                    <span className="logo_box_header_two_title">
                                        User Station
                                    </span>
                                </div>

                                <div className="logo_box_header_two">
                                    <span className="logo_box_header_two_title_three">
                                        User Login
                                    </span>
                                </div>

                                {
                                    this.state.danger ? <span className="text-danger small_font">{this.state.danger}</span> : <span></span>
                                }
                                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} >
                                    <Form.Group className="mb-3" controlId="username">
                                        <InputGroup>
                                            <InputGroup.Text id="btnGroupAddon2">
                                                <img className="login_imput_logo" alt="" src={userSvg}></img>
                                            </InputGroup.Text>
                                            {/*onChange={(e)=>this.changeEvent(e)}*/}
                                            <Form.Control autoComplete="off" type="text" placeholder="Login Name*" defaultValue={this.state.username}
                                                          tabIndex={1}
                                                          required />
                                        </InputGroup>
                                    </Form.Group>

                                    {/*isInvalid={true}*/}
                                    <Form.Group className="mb-3" controlId="password">
                                        <InputGroup>
                                            <InputGroup.Text id="btnGroupAddon2">
                                                <img className="login_imput_logo" alt="" src={passSvg}></img>
                                            </InputGroup.Text>
                                            <Form.Control tabIndex={2} type="password" placeholder="Password*" defaultValue={this.state.password} required />
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="rememberCheckbox">
                                        <Form.Check tabIndex={3} className="login_form_label" defaultChecked={this.state.checked} type="checkbox" label="Remember me" />
                                        <span tabIndex={4} className="login_form_label_a blue-color" onClick={this.forget.bind(this)}><u>Forgot password?</u></span>
                                    </Form.Group>

                                    <input type="submit" tabIndex={5} className="button w-100 py-2" value="Login" />

                                    <div className="logo_box_bottom_one">
                                        <span>
                                            By continuing, you confirm that you've read our
                                        </span><br/>
                                        <span>
                                            <u className="blue-color" tabIndex={6}>
                                                Term & Conditions
                                            </u>
                                        </span>
                                        <span> and </span>
                                        <span>
                                            <u className="blue-color" tabIndex={7} onKeyDown={this.onKeyDown.bind(this,'username')}>
                                                Cookie Policy.
                                            </u>
                                        </span>
                                    </div>
                                </Form>
                            </Col>
                            <Col xs={12} className="text-center xs_font light_color px-2 mt-2 mb-0">
                                <span>(c) 2016 WizDepot. All Rights Reserved</span>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default connect(
    state=>({isLoginUsr:state.userInfo.isLoginUsr}),
    {
        saveUserInfo:createSaveUserInfoAction,
    }
)(Login)