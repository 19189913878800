import axios from 'axios'
import store from "../redux/store";
import {createDeleteUserInfoAction} from "../redux/actions/login_action";

const instance = axios.create({
    timeout:30000,
});

instance.interceptors.request.use((config)=>{
    const {tokenUsr} = store.getState().userInfo;
    if(tokenUsr) config.headers.Authorization = '' + tokenUsr;

    // const {method,data} = config;
    // if(method.toLowerCase() === 'post'){
    //     if(data instanceof Object){
    //         config.data = qs.stringify(data)
    //     }
    // }
    return config;
});

instance.interceptors.response.use(
    (response)=>{
        return response.data;
    },
    (error) =>{
        if(error.response.status === 401){
            store.dispatch(createDeleteUserInfoAction())
        }
        if(error.response.status === 400){
           //  console.log(error.response)
            return error.response.data;
        }
        return new Promise(()=>{})
    }
);

export default instance