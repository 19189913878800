import React,{Component} from "react";
import {Switch,Route,Redirect} from 'react-router-dom'
import Login from "./containers/login/login";
import Admin from "./containers/admin/admin";
import Forget from "./pages/forgot/forget";
import EmailUrl from "./pages/email_url/email_url";
import {getPackageJson} from "./config/commom";
import {POLLINGTIME} from "./config";

export default class App extends Component{
    componentDidMount() {
        global.tokenTime = setInterval(function(){
            getPackageJson();
        },POLLINGTIME)
    }

    componentWillUnmount() {
        window.clearInterval(global.tokenTime);
    }

    render() {
        return (
            <div className="App">
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/admin" component={Admin}/>
                    <Route path="/forget" component={Forget}/>
                    <Route path="/password-reset" component={EmailUrl}/>
                    <Redirect to="/login" />
                </Switch>
            </div>
        )
    }
}

