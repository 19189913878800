import React, {Component} from 'react';
import {connect} from "react-redux";
import PubSub from 'pubsub-js'
import {Container, Col, Form, Row,Modal,Spinner} from "react-bootstrap";
import "./user_profile.css"
import {getUser, getUserPhoto, changeUserPassword, updateUser, uploadUserPhoto,deleteUserPhoto} from "../../api";
import user_default from '../../assets/images/user-default.png'
import AlertTips from "../alert/alert_tips";
import {commonValidate,commonSuperValidate,commonPassWordValidate} from "../../config/commonValidate";
import Loading from "../loading/loading";
import {getPackageJson} from "../../config/commom";
import DraggableDiv from "../draggable/draggableDiv";
import {
    ALL_REQUIRED,
    ALL_REQUIRED_INFORMATION,
    EDIT_ALREADY_BODY,
    IMG_FILE_MAX_SIZE,
    PASS_CPASS_NOT_MATCH
} from "../../config/message";
import {MessageControl} from "../../config/alert_message";

class UserProfile extends Component {
    state = {
        user:{},
        uid:'',
        nameEditable: true,
        emailEditable: true,
        nameFirstEditable:true,
        nameLastEditable:true,
        cellEditable: true,
        officeEditable: true,
        reset: false,
        userPreviewImg: user_default,
        error_message: '',
        editPass:{},
        errors:{},
        validate:false,
        AlertTip:{
            status:false,
            body:'',
        },
        loading:false,
        saveStat:false,
        firstName:'',
        lastName:'',
        userName:'',
        email:'',
        cellPhone:'',
        officePhone:'',
        validatedManage:false,
        userSaveStatus:false,
    }

    componentDidMount() {
        this.inputElement = React.createRef();
        this.submitEditPassElement = React.createRef();
        this.submitElement = React.createRef();
        const {uidUsr} = this.props.userInfo;
        this.setState({
            uid:uidUsr,
        });

        getPackageJson();
        this.getUser(uidUsr);
        this.getUserPhoto(uidUsr);
    }

    getUser = async(uid)=>{
        this.setState({
            loading:true,
        });
        let resultUser = await getUser(uid);
        this.setState({
            user:resultUser,
            firstName:resultUser.firstName,
            lastName:resultUser.lastName,
            userName:resultUser.userName,
            email:resultUser.email,
            cellPhone:resultUser.cellPhone,
            officePhone:resultUser.officePhone,
            loading:false,
        });
    }

    //get photo
    getUserPhoto = async(uid)=>{
        let photo = await getUserPhoto(uid);
        let blob = new Blob([photo],{type: "image/png,image/jpg,image/jpeg,image/bmp"});
        let url = window.URL.createObjectURL(blob);
        var Img = new Image();
        Img.src = url;
        Img.onerror = function(err) {
            url=user_default;
        }
        this.setState({
            userPreviewImg:url,
        });
    };

    handleFormChange = (e) => {
        // e.target.value=e.target.value.toString().replace(/(^\s*)/g, "");
        // e.target.value=extractLength(e.target.maxLength,e.target.value);
        let fdStart = e.target.value.indexOf(" ");
        if (fdStart === 0){
            e.target.value=e.target.value.toString().replace(/(^\s*)/g, "");
        }
        let errors = this.state.errors;
        const name = e.target.name;
        let validate = commonSuperValidate(e.target.name,e.target.value);

        if(validate != true){
            errors[e.target.name] = 'form-control is-invalid';
        }else {
            errors[e.target.name] = 'form-control is-validWeb';
        }
        this.setState({
            [name]: e.target.value,
            errors
        });
    }

    //upload photo
    updateSuperPhoto=(event)=>{
        event.preventDefault();
        this.inputElement.current.click()
    };

    onChangeInput=async(event)=>{
        event.preventDefault();
        const fileUploaded = event.target.files[0];
        if(fileUploaded){
            const size = fileUploaded.size / 1024;
            if (size > 1024) {
                this.AlertTip(true,IMG_FILE_MAX_SIZE);
                event.target.value = '';
                return;
            }
            let validate = commonValidate('upload photo',fileUploaded.name);
            if(validate != true){
                this.AlertTip(true,validate);
                event.target.value = '';
                return ;
            }

            var formData = new FormData();
            formData.append("user", this.state.uid);
            formData.append("itemtype", 'EmployeePhoto');
            formData.append("file", fileUploaded);
            let result = await uploadUserPhoto(formData);
            const {code,msg} = result;
            if (code === 1) {
                PubSub.publish('uploadPhoto', true)
                this.setState({
                    userPreviewImg: URL.createObjectURL(fileUploaded),
                });
            }else {
                let message=MessageControl('upload',msg);
                this.AlertTip(true,message);
            }
        }else {
            this.setState({
                userPreviewImg: '',
            });
        }
    }

    deleteUserPhoto=async ()=>{
        let result = await deleteUserPhoto(this.state.uid);
        const {code,msg} = result;
        if (code === 1) {
            PubSub.publish('uploadPhoto', false)
            this.inputElement.current.value='';
            this.setState({
                userPreviewImg: user_default,
            });
        }else {
            let message=MessageControl('delete_photo',msg);
            this.AlertTip(true,message);
        }
    }

    handleChangePass = (e) => {
        // e.target.value=e.target.value.toString().replace(/(^\s*)/g, "");
        let fdStart = e.target.value.indexOf(" ");
        if (fdStart === 0){
            e.target.value=e.target.value.toString().replace(/(^\s*)/g, "");
        }
        let editPass = this.state.editPass;
        let errors = this.state.errors;
        editPass[e.target.name] = e.target.value;
        let validate = commonPassWordValidate(e.target.name,e.target.value);

        if(validate != true){
            errors[e.target.name] = 'form-control is-invalid';
        }else {
            errors[e.target.name] = 'form-control is-validWeb';
        }
        this.setState({ editPass ,errors});
    }

    saveManagerUserProfile=(event)=>{
        event.preventDefault();
        this.submitElement.current.click()
    }

    saveUser=async (event)=>{
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.AlertTip(true,ALL_REQUIRED_INFORMATION);
            this.setState({
                validatedManage:true,
            });
        }else {
            if(this.state.userSaveStatus === true){
                return ;
            }
            let user = this.state.user;
            user.firstName=this.state.firstName;
            user.lastName=this.state.lastName;
            user.userName=this.state.userName;
            user.email=this.state.email;
            user.cellPhone=this.state.cellPhone;
            user.officePhone=this.state.officePhone;
            for (let i in user) {
                if(user[i] != ''){
                    let validate = commonSuperValidate(i,user[i]);
                    if(validate != true){
                        this.AlertTip(true,validate);
                        return ;
                    }
                }
            }
            this.setState({
                userSaveStatus:true,
            })
            let result = await updateUser(this.state.uid,this.state.user);
            const {code,msg} = result;
            if (code === 1) {
                this.getUser(this.state.uid);
                this.setState({
                    nameFirstEditable: true,
                    nameEditable: true,
                    emailEditable: true,
                    nameLastEditable:true,
                    cellEditable: true,
                    officeEditable: true,
                    reset: false,
                    errors:{},
                    userSaveStatus:false,
                });
            }else if(code === -2){
                this.AlertTip(true,EDIT_ALREADY_BODY);
                this.setState({
                    userSaveStatus:false,
                });
            }else {
                let message=MessageControl('user_profile',msg);
                this.AlertTip(true,message);
                this.setState({
                    userSaveStatus:false,
                });
            }
            this.setState({
                validatedManage:false,
            })
        }
    }

    clearPass = () => {
        this.setState({
            reset: false,
            error_message:'',
            validated: false,
            editPass:{},
            errors:{},
        });
    }

    resetPassword = async (event) => {
        event.preventDefault();
        this.submitEditPassElement.current.click()
    }

    submitEditPass= async (event)=> {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.setState({
                error_message: ALL_REQUIRED,
                validated: true,
            });
        } else {
            let editPass = this.state.editPass;
            if (editPass.password && editPass.npassword === editPass.cnpassword) {
                let body = {userID:this.state.uid,userName:this.state.user.userName,oldPassword:editPass.password,newPassword:editPass.npassword};
                for (let i in body) {
                    if(body[i] != ''){
                        let validate = commonPassWordValidate(i,body[i]);
                        if(validate != true){
                            this.setState({ error_message: validate })
                            return ;
                        }
                    }
                }
                this.setState({
                    saveStat:true,
                })
                let result = await changeUserPassword(body)
                const {code,msg} = result;
                if(code === 1){
                    this.setState({
                        reset: false,
                        error_message:'',
                        errors:{},
                        validated: false,
                        saveStat:false,
                        editPass:{},
                    });
                }else {
                    let message=MessageControl('edit_password',msg);
                    this.setState({ error_message: message, saveStat:false });
                }
            } else {
                this.setState({
                    error_message: PASS_CPASS_NOT_MATCH
                });
            }
        }
    }

    AlertTip=async(status,msg)=>{
        let AlertTip =this.state.AlertTip;
        AlertTip.status=status;
        AlertTip.body=msg;
        this.setState({
            AlertTip
        },()=>{
            if(status === true){
                document.getElementById('btn_close_tip').focus()
            }
            if(status === false && msg === EDIT_ALREADY_BODY){
                this.getUser(this.state.uid);
            }
        })
    }

    cancelManager=(name,e)=>{
        let user =this.state.user;
        let errors =this.state.errors;
        this.setState({
            validatedManage:false,
        })
        switch (name) {
            case 'nameFirstEditable':
                errors['firstName'] = 'form-control';
                this.setState({
                    nameFirstEditable:true,
                    errors,
                    firstName: user.firstName
                });
                break;
            case 'nameEditable':
                errors['userName'] = 'form-control';
                this.setState({
                    nameEditable:true,
                    errors,
                    userName: user.userName
                });
                break;
            case 'emailEditable':
                errors['email'] = 'form-control';
                this.setState({
                    emailEditable:true,
                    errors,
                    email: user.email
                });
                break;
            case 'nameLastEditable':
                errors['lastName'] = 'form-control';
                this.setState({
                    nameLastEditable:true,
                    errors,
                    lastName: user.lastName
                });
                break;
            case 'cellEditable':
                errors['cellPhone'] = 'form-control';
                this.setState({
                    cellEditable:true,
                    errors,
                    cellPhone:user.cellPhone
                });
                break;
            case 'officeEditable':
                errors['officePhone'] = 'form-control';
                this.setState({
                    officeEditable:true,
                    errors,
                    officePhone:user.officePhone
                });
                break;
            default :
                break;
        }
    }

    resetPass=()=>{
        this.setState({
            reset:true,
        },()=>{
            document.getElementById('resetPass').focus();
        })
    }

    onKeyDown = (index,event) => {
        if (event.key === "Tab") {
            event.preventDefault();
            document.getElementById(index).focus();
        }
    };

    componentWillUnmount() {
        this.setState = ()=>false;
    }

    render() {
        const {userPreviewImg,errors,editPass,saveStat} = this.state;
        const {firstName,lastName,userName,email,cellPhone,officePhone} = this.state;

        const resetPass=(
            <>
                <div className="modal-header drag-handler">
                    <div className="h6 modal-title h4">Reset Password</div>
                    <button tabIndex={29} type="button" id="btn_close" className="btn-close" aria-label="Close" onClick={this.clearPass.bind(this)}></button>
                </div>
                <Modal.Body className="show-grid small_font">
                    <Container>
                        <Form noValidate validated={this.state.validated} onSubmit={this.submitEditPass} >
                            <span className="text-danger xs_font width-100">{this.state.error_message}</span>
                            <div className="form-group">
                                <label className="mb-md-2 col-12"><b className="ec-text-info">*</b>Current Password</label>
                                <input tabIndex={25} id="resetPass" type="password" value={editPass.password || ''} name="password" onChange={this.handleChangePass}
                                       required className={(errors["password"] ? errors["password"] : 'form-control')} placeholder="Enter Current Password" />
                            </div>

                            <div className="form-group">
                                <label className="mb-md-2 col-12"><b className="ec-text-info">*</b>New Password</label>
                                <input tabIndex={26} type="password" value={editPass.npassword || ''} name="npassword" onChange={this.handleChangePass}
                                       required className={(errors["npassword"] ? errors["npassword"] : 'form-control')} placeholder="Enter New Password" />
                            </div>
                            <div className="form-group">
                                <label className="mb-md-2 col-12"><b className="ec-text-info">*</b>Confirm New Password</label>
                                <input tabIndex={27} type="password" value={editPass.cnpassword || ''} name="cnpassword" onChange={this.handleChangePass}
                                       required className={(errors["cnpassword"] ? errors["cnpassword"] : 'form-control')} placeholder="Enter Confirm New Password" />
                            </div>
                            <input hidden type="submit" ref={this.submitEditPassElement} value="submit" />
                        </Form>

                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ul className="row form-group m-0 pr-0 list-inline">
                        <li className="width_90"><button onClick={this.clearPass.bind(this)} tabIndex={29} onKeyDown={this.onKeyDown.bind(this,'resetPass')}
                                                         className="button cancel-btn py-2 px-4 m-0 width_80">Close</button></li>
                        <li className="width_90">
                            {
                                saveStat===true ?
                                    <button tabIndex={33} className="button resend-btn px-4 py-2 m-0">
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    </button>
                                    :
                                    <button onClick={this.resetPassword.bind(this)} tabIndex={28}
                                            className="button resend-btn py-2 px-4 m-0 width_80">Save</button>
                            }
                        </li>
                    </ul>
                </Modal.Footer>
            </>
        )
        return (
            <div className="App">
                <Loading loading={this.state.loading} />
                <AlertTips AlertTip={this.AlertTip.bind(this)} {...this.state.AlertTip} />

                <Container>
                    <div className="content p-0 pt-4 width-80 small_font">
                        <div>
                            <h6>User Profile (Account Holder)</h6>
                        </div>
                        <div className="my-4">
                            <Form noValidate validated={this.state.validatedManage} onSubmit={this.saveUser} >
                                <div className="row form-group">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <label><b className="ec-text-info">*</b>First Name</label>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <input tabIndex={10} type="text" autoComplete="off" value={firstName} name="firstName" onChange={this.handleFormChange}
                                               maxLength={128} required
                                               disabled={this.state.nameFirstEditable} className={(errors["firstName"] ? errors["firstName"] : 'form-control')} placeholder="Enter First Name" />
                                    </div>
                                    <div style={this.state.nameFirstEditable === true ? { display: 'none' } : {}} className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={11} className="link-style p-0" onClick={this.cancelManager.bind(this, 'nameFirstEditable')}>Cancel</span> |
                                        <span tabIndex={11} className="link-style p-2" onClick={this.saveManagerUserProfile.bind(this)}>Save</span>
                                    </div>
                                    <div style={this.state.nameFirstEditable === true ? {} : { display: 'none' }} className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={11} className="link-style p-0 mt-1" onClick={() => this.setState({ nameFirstEditable: false })}>Change</span>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <label><b className="ec-text-info">*</b>Last Name</label>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <input tabIndex={12} type="text" autoComplete="off" value={lastName} name="lastName" onChange={this.handleFormChange}
                                               maxLength={128} required
                                               disabled={this.state.nameLastEditable} className={(errors["lastName"] ? errors["lastName"] : 'form-control')} placeholder="Enter Last Name" />
                                    </div>
                                    <div style={this.state.nameLastEditable === true ? { display: 'none' } : {}} className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={13} className="link-style p-0" onClick={this.cancelManager.bind(this, 'nameLastEditable')}>Cancel</span> |
                                        <span tabIndex={13} className="link-style p-2" onClick={this.saveManagerUserProfile.bind(this)}>Save</span>
                                    </div>
                                    <div style={this.state.nameLastEditable === true ? {} : { display: 'none' }} className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={13} className="link-style p-0 mt-1" onClick={() => this.setState({ nameLastEditable: false })}>Change</span>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <label><b className="ec-text-info">*</b>Login Name</label>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <input tabIndex={14} type="text" autoComplete="off" value={userName} name="userName" onChange={this.handleFormChange}
                                               maxLength={128} required
                                               disabled={this.state.nameEditable} className={(errors["userName"] ? errors["userName"] : 'form-control')} placeholder="Enter Login Name" />
                                    </div>
                                    <div style={this.state.nameEditable === true ? { display: 'none' } : {}} className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={15} className="link-style p-0" onClick={this.cancelManager.bind(this, 'nameEditable')}>Cancel</span> |
                                        <span tabIndex={15} className="link-style p-2" onClick={this.saveManagerUserProfile.bind(this)}>Save</span>
                                    </div>
                                    <div style={this.state.nameEditable === true ? {} : { display: 'none' }} className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={15} className="link-style p-0 mt-1" onClick={() => this.setState({ nameEditable: false })}>Change</span>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <label><b className="ec-text-info">*</b>Email</label>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <input tabIndex={16} type="text" value={email} name="email" onChange={this.handleFormChange}
                                               maxLength={255} required
                                               disabled={this.state.emailEditable} className={(errors["email"] ? errors["email"] : 'form-control')} placeholder="Enter Email" />
                                    </div>
                                    <div style={this.state.emailEditable === true ? { display: 'none' } : {}} className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={17} className="link-style p-0" onClick={this.cancelManager.bind(this, 'emailEditable')}>Cancel</span> |
                                        <span tabIndex={17} className="link-style p-2" onClick={this.saveManagerUserProfile.bind(this)}>Save</span>
                                    </div>
                                    <div style={this.state.emailEditable === true ? {} : { display: 'none' }} className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={17} className="link-style p-0 mt-1" onClick={() => this.setState({ emailEditable: false })}>Change</span>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <label>Password</label>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <input tabIndex={18} type="password" disabled='disabled' className="form-control" value="*******" placeholder="Enter Password"
                                               name="confirm_password" />
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={19} className="link-style p-0" onClick={this.resetPass.bind(this)}>Reset</span>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <label><b className="ec-text-info">*</b>Cell Phone</label>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <input tabIndex={20} type="text" autoComplete="off" value={cellPhone} name="cellPhone" onChange={this.handleFormChange}
                                               maxLength={20} required
                                               disabled={this.state.cellEditable} className={(errors["cellPhone"] ? errors["cellPhone"] : 'form-control')} placeholder="Enter Cell Phone" />
                                    </div>
                                    <div style={this.state.cellEditable === true ? { display: 'none' } : {}} className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={21} className="link-style p-0" onClick={this.cancelManager.bind(this, 'cellEditable')}>Cancel</span> |
                                        <span tabIndex={21} className="link-style p-2" onClick={this.saveManagerUserProfile.bind(this)}>Save</span>
                                    </div>
                                    <div style={this.state.cellEditable === true ? {} : { display: 'none' }} className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={21} className="link-style p-0 mt-1" onClick={() => this.setState({ cellEditable: false })}>Change</span>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <label>Office Phone</label>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <input tabIndex={22} type="text" autoComplete="off" value={officePhone} name="officePhone" onChange={this.handleFormChange}
                                               maxLength={20}
                                               disabled={this.state.officeEditable} className={(errors["officePhone"] ? errors["officePhone"] : 'form-control')} placeholder="Enter Office Phone" />
                                    </div>
                                    <div style={this.state.officeEditable === true ? { display: 'none' } : {}} className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={23} className="link-style p-0" onClick={this.cancelManager.bind(this, 'officeEditable')}>Cancel</span> |
                                        <span tabIndex={23} className="link-style p-2" onClick={this.saveManagerUserProfile.bind(this)}>Save</span>
                                    </div>
                                    <div style={this.state.officeEditable === true ? {} : { display: 'none' }} className="col-xl-2 col-lg-2 col-md-2 col-sm-12 pt-2">
                                        <span tabIndex={23} className="link-style p-0 mt-1" onClick={() => this.setState({ officeEditable: false })}>Change</span>
                                    </div>
                                </div>
                                <input hidden type="submit" ref={this.submitElement} value="submit" />
                            </Form>

                        </div>
                        <div>
                            <div className="border-bottom">
                                <p className="mb-0">Change Your Profile Photo</p>
                            </div>
                            <Row className="p-3 text-center">
                                <Col lg={3} md={3} sm="12">
                                    <img alt="Banner" className="profile-img" src={userPreviewImg} />
                                    <p>File size limit: 1 MB</p>
                                </Col>
                                <Col lg={9} md={9} sm={12} className="mt-5">
                                    <div className="col-md-4">
                                        <div className="action float-start">
                                            <input hidden type="file" id="file" accept=".png, .jpg, .jpeg" ref={this.inputElement} onChange={e=>this.onChangeInput(e)} />
                                            <u tabIndex={24} className="cursor_pointer black-color small_font" onClick={this.updateSuperPhoto.bind(this)}>Replace</u>
                                            <span className="gap">|</span>
                                            <u tabIndex={24} onKeyDown={this.onKeyDown.bind(this,'timesheet')}
                                               className="cursor_pointer black-color small_font" onClick={this.deleteUserPhoto.bind(this)}>Delete</u>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {this.state.reset == true ?
                            <DraggableDiv body={resetPass}></DraggableDiv>
                            :''}
                    </div>
                </Container>
            </div>
        );
    }
}

export default connect(
    state=>({userInfo:state.userInfo}),
    {
    }
)(UserProfile)