import {SAVE_USER_INFO,DELETE_USER_INFO} from "../action_types";
import {getPackageJson} from "../../config/commom";

export const createSaveUserInfoAction = (value)=> {
    // localStorage.setItem('uid',JSON.stringify(value.returnValue));
    localStorage.setItem('uidUsr',value.returnValue);
    localStorage.setItem('tokenUsr',value.msg);
    return {type:SAVE_USER_INFO,data:value}
}

export const createDeleteUserInfoAction = ()=> {
    localStorage.removeItem('uidUsr');
    localStorage.removeItem('tokenUsr');
    getPackageJson();
    return {type:DELETE_USER_INFO}
}