import React, {Component} from 'react';
import "./iframe_box.css"
import {DOMAINURL, ONTIME, ONTIMEROUTE} from "../../config";
import {connect} from "react-redux";
import {createDeleteUserInfoAction} from "../../redux/actions/login_action";

class IframeBox extends Component {
    state={
        iwidth:"",
        iheight:"",
        itransform:"",
        itransformOrigin:""
    };

    componentDidMount() {
        document.domain = DOMAINURL;
    }

    setHeight=()=>{
        var that=this;
        window.addEventListener('message', function (e) {
            if (e.origin == ONTIME && e.data.id=='user_son' && e.data.height >= 0) {
                let dom = document.getElementById('IframeInhorBox');
                that.setState({
                    iwidth:dom.scrollWidth + 'px',
                    iheight:e.data.height+20 + 'px',
                    itransform : 1,
                    itransformOrigin : '0 0',
                });
                // document.getElementById('IframeInhorBox').style.height = data.height + 'px';
            }

            if (e.origin==ONTIME && e.data.id=='user_son' && e.data.error==401) {
                that.logout();
            }
            if(e.origin==ONTIME && e.data.id=='user_son' && e.data.overtime == true){
                let lastTime = new Date().getTime();
                localStorage.setItem('user_lasttime',lastTime);
            }
        }, false);
        let uid=that.props.uidUsr;
        let token=that.props.tokenUsr;
        const iframe = document.getElementById('iframe');
        iframe.contentWindow.postMessage({
            id: 'user_father',
            uid: uid,
            token:token,
        }, '*')

        window.addEventListener('resize', this.setPageWidth)
    };

    logout(){
        window.clearInterval(global.overtime);
        this.props.deleteUserInfo()
    }

    setPageWidth = (e) => {
        if(document.getElementById("IframeInhorBox")){
            let width= document.getElementById("IframeInhorBox").offsetWidth;
            // let height= e.target.innerHeight;
            const iframe = document.getElementById('iframe');
            iframe.contentWindow.postMessage({
                id: 'user_father',
                width: width,
                // height:height,
            }, '*')
            this.setState({
                iwidth:width + 'px',
                // iheight:height + 'px',
            });
        }
    }

    componentWillUnmount() {
        this.setState = ()=>false;
    }

    render() {
        let iframeStyle = {
            width:this.state.iwidth?this.state.iwidth:'100%',
            // height:this.state.iheight?this.state.iheight:'100%',
            // minHeight: 800,
            height:this.state.iheight?this.state.iheight:'0',
            minHeight: 0,
            transform:"scale("+this.state.itransform+")",
            transformOrigin:this.state.itransformOrigin
        }
        return (
            <div id='IframeBox' className="IframeBox">
                <div id='IframeInhorBox' className="IframeInhorBox">
                    <iframe style={iframeStyle}
                            id = 'iframe'
                            title='onTime'
                            src={ONTIMEROUTE}
                            frameBorder='0'
                            onLoad={this.setHeight.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default connect(
    state=>({userInfo:state.userInfo}),
    {
        deleteUserInfo:createDeleteUserInfoAction
    }
)(IframeBox)