// export const BASE_URL = 'http://127.0.0.1:3000/api1';
export const BASE_URL = 'https://www.wizdepot.com';
//export const BASE_URL = 'https://52.200.135.187';

// export const ONTIME ='http://a.com';
// export const ONTIMEROUTE ='http://a.com/on-time/#/admin/user/timesheet';
// export const DOMAINURL='a.com';

export const ONTIME ='https://www.wizdepot.com';
export const ONTIMEROUTE ='https://www.wizdepot.com/on-time/#/admin/user/timesheet';
export const DOMAINURL='www.wizdepot.com';

export const TIMEOUT=1200 * 1000;
export const TRIGGERTIMEOUT=2 * 1000;
export const TOKENRESETTIME = 600 * 1000;
export const POLLINGTIME = 300 * 1000;