import {SAVE_USER_INFO,DELETE_USER_INFO} from "../action_types";

let uid = JSON.parse(localStorage.getItem('uidUsr'));
let token = localStorage.getItem('tokenUsr');

let initState = {
    uidUsr:uid || '',
    tokenUsr:token || '',
    isLoginUsr:uid && token ? true : false,
};

export default function test(preState=initState,action) {
    const {type,data} =action;
    let newState;
    switch(type) {
        case SAVE_USER_INFO:
            newState = {uidUsr:data.returnValue,tokenUsr: data.msg,isLoginUsr: true};
            return newState;
        case DELETE_USER_INFO:
            newState = {uidUsr:'',tokenUsr: '',isLoginUsr: false};
            return newState;
        default:
            return preState
    }
}