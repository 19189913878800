import React, {useState} from 'react';
import {Spinner} from "react-bootstrap";
import "./loading.css"

export default function Loading(props) {
    const {loading} = props;
    const [show, setShow] = useState(loading);
    if(loading != show){
        setShow(loading)
    }

    return(
        <div>
            {loading === true ?
                <div>
                    <div className="fade modal-backdrop show"></div>
                    <div role="dialog" aria-modal="false" className="modal modal_dia_div" tabIndex="-1">
                        <div className="loading">
                            <Spinner animation="border" />
                            <div className="loading_content">loading...</div>
                        </div>
                    </div>
                </div>:''
            }
        </div>
    )
}







